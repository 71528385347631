import React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { NavLink } from 'react-router-dom';
import "./style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeXuan } from '../../store/pageSlice';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
const Home = () => {
    const [isLogin, setIsLogin] = useState(false)
    // const [xuan, setxuan] = useState(1)

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { pageSlice } = useSelector(state => state);
    const nav = useNavigate()
    useEffect(() => {


        document.title = '2024年小鹏汽车经销商投资人大会'
        if(localStorage.getItem('peng_time')&&localStorage.getItem('peng_token')){
            let date=new Date()
            let time=date.getTime()
            if(localStorage.getItem('peng_time')>time){

            }else{
                nav('/login',{replace:true})
                localStorage.removeItem('peng_token')
                localStorage.removeItem('peng_time')
            }
        }else{
            localStorage.removeItem('peng_token')
            localStorage.removeItem('peng_time')
            nav('/login',{replace:true})
        }
    }, [])

    const tuichuHandle = () => {
        localStorage.removeItem('peng_token')
        localStorage.removeItem('peng_time')
        nav('/login', { replace: true })
    }

    const loginItem = () => {
        return (
            <>
                <Outlet />

                <div className='home'>

                    <div className="overlap-wrapper">
                        <div className="overlap">

                            <NavLink to='/home/application' replace onClick={() => { dispatch(changeXuan(3)) }}>
                                <div className="overlap-5">
                                    <img
                                        className="img-3"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 3 ? "/img/b1.svg" : "/img/b0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" style={t('is_en') == 1 ? { left: '-12px' } : {}}>{t("nav_3")}</div>
                                </div>
                            </NavLink>

                            <NavLink to='/home/information' replace onClick={() => { dispatch(changeXuan(2)) }}>
                                <div className="overlap-3">
                                    <div className="text-wrapper-2" style={t('is_en') == 1 ? { left: '-8px' } : {}}>{t("nav_2")}</div>
                                    <div className="view-2">
                                        <img
                                            className="img-2"
                                            alt="Element"
                                            src={
                                                pageSlice.xuan === 2 ? "/img/x1.svg" : "/img/x0.svg"
                                            }
                                        />
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink to='/home/recommend' replace onClick={() => {
                                dispatch(changeXuan(4))
                            }}>
                                <div className="overlap-2">
                                    <img
                                        className="img-4"
                                        alt="Element"
                                        src={
                                            pageSlice.xuan === 4 ? "/img/t1.svg" : "/img/t0.svg"
                                        }
                                    />
                                    <div className="text-wrapper-2" style={t('is_en') == 1 ? { left: '13px' } : {}}>{t("nav_4")}</div>
                                </div>
                            </NavLink>

                            <NavLink to='/home/moment' replace onClick={() => {
                                dispatch(changeXuan(5))
                            }}>
                                <div className="overlap-7">
                                    <div className="view-2">
                                        <img
                                            className="img-5"
                                            alt="Element"
                                            src={
                                                pageSlice.xuan === 5 ? "/img/s1.svg" : "/img/s0.svg"
                                            }
                                        />
                                    </div>
                                    <div className="text-wrapper-2" style={t('is_en') == 1 ? { left: '10px' } : {}}>{t("nav_5")}</div>
                                </div>
                            </NavLink>
                
                            





                            <div className='tuichu' onClick={tuichuHandle}>
                                <div className="overlap-7">
                                    <div className="view-2">
                                        <img
                                            className="img-tuichu"
                                            alt="Element"
                                            src={
                                                  "/img/tuichu.jpg"
                                            }
                                        />
                                    </div>
                                    <div className="text-wrapper-2" style={t('is_en') == 1 ? { left: '20px' } : {}}>{t("退出")}</div>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </>

        )
    }


    return (
        <div>

            {
                loginItem()
            }

        </div>
    );
}

export default Home;
