import React, { Component, useRef } from 'react';
import "./style.css";
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { changeXuan } from '../../store/pageSlice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Translate from '../../components/Translate';
const Information = () => {
    const nav = useNavigate()
    const dispatch = useDispatch();
    const { pageSlice } = useSelector(state => state);
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState('zh')
    const sepRef = useRef();

    const [dao, setDao] = useState(1)
    const [dao2, setDao2] = useState("#xinxi")
    const [dao3, setDao3] = useState("#richeng")
    const [dao4, setDao4] = useState("")
    function logIt() {
        let offsetTop = sepRef.current.offsetTop - window.pageYOffset;
        console.log(offsetTop)
        if (offsetTop < -1920) {
            console.log('first-------4')
            setDao(4)
            setDao2('#map')
            setDao3("#tingche")
        } else

            if (offsetTop <= -1600 && offsetTop > -1920) {
                console.log('first-------3')
                setDao(3)
                setDao2('#richeng')
                setDao3("#tingche")
            } else

                if (offsetTop < -1190 && offsetTop > -1600) {
                    console.log('first-------2')
                    setDao(2)
                    setDao2('#xinxi')
                    setDao3("#map")
                } else if (offsetTop >= -1190) {
                    console.log('first-------1')
                    setDao(1)
                    setDao2('#xinxi')
                    setDao3("#richeng")
                }
    }
    useEffect(() => {
        document.documentElement.scrollTop = 0
        dispatch(changeXuan(2))
        function watchScroll() {
            window.addEventListener("scroll", logIt);
        }
        watchScroll();
        // Remove listener (like componentWillUnmount)
        return () => {
            window.removeEventListener("scroll", logIt);
        };
    }, [])

    return (
        <>
            {/* <div className='recommend daohang'>
                <div className="daohang">
                    <a href={dao2}>
                        <img src="/img/shangjian.png" alt="" className="shangjian" onClick={() => {
                            // console.log(dao+'----dao')
                            // console.log(dao2+'----dao2')
                            if (dao == 1) {
                                // setDao(2)
                                setDao(1)
                                setDao2('#xinxi')
                            } else if (dao == 2) {
                                setDao(1)
                                setDao2('#xinxi')
                            } else if (dao == 3) {
                                setDao(2)
                                setDao2('#richeng')
                            } else if (dao == 4) {
                                setDao(3)
                                setDao2('#map')
                            }
                        }} />
                    </a>


                    <a href="#xinxi" onClick={() => {
                        setDao(1)
                        setDao2('#xinxi')
                        setDao3(('#richeng'))
                    }}>
                        <div className="junlan item" style={
                            dao == 1 ? { background: '#96b414', color: "#fff" } : {}
                        }>
                            {t("会议信息")}
                        </div>
                    </a>
                    <a href="#richeng" onClick={() => {
                        setDao(2)
                        setDao2('#xinxi')
                        setDao3(('#map'))
                    }}>
                        <div className="rihang item" style={
                            dao == 2 ? { background: '#96b414', color: "#fff" } : {}
                        }>
                            {t("会议日程")}
                        </div>
                    </a>
                    <a href="#map" onClick={() => {
                        setDao(3)
                        setDao2('#richeng')
                        setDao3('#tingche')
                    }}>
                        <div className="feicui item" style={
                            dao == 3 ? { background: '#96b414', color: "#fff" } : {}
                        }>
                            {t("地图导览")}
                        </div>

                    </a>
                    <a href="#tingche" onClick={() => {
                        setDao(4)
                        setDao2('#map')
                        setDao3('#tingche')
                    }}>
                        <div className="feicui item" style={
                            dao == 4 ? { background: '#96b414', color: "#fff" } : {}
                        }>
                            {t("自驾停车")}
                        </div>

                    </a>
                    <a href={dao3}>
                        <img src="/img/xiajian.png" alt="" className="xiajian" onClick={() => {
                            console.log(dao)
                            console.log(dao2)
                            console.log(dao3)
                            if (dao == 1) {
                                setDao(2)
                                setDao2('#xinxi')
                                setDao3('#richeng')

                            } else if (dao == 2) {
                                setDao(3)
                                setDao2('#richeng')
                                setDao3('#map')

                            } else if (dao == 3) {
                                setDao(4)
                                setDao2('#map')
                                setDao3('#tingche')

                            }
                            else if (dao == 4) {
                                setDao(4)
                                setDao2('#map')
                                setDao3('#tingche')

                            }
                        }} />
                    </a>


                </div>
            </div> */}
            <div className='information '>
                <div ref={sepRef}></div>


                <img src="/img/2.jpeg" alt="" />
                {/* <Translate /> */}
               
                        
                        <>
                            <div><a name='xinxi'></a></div>
                            <img src="/img/info-img-2-4.jpg" alt="" style={{ }} />
                            
                            
                        </>

        




            </div>
        </>

    );
}


export default Information;
