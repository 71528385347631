import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Home from '../pages/home';
import Application from '../pages/application';
import Information from '../pages/information';
import Recommend from '../pages/recommend';
import Login from '../pages/login';
import Moment from '../pages/moment';
import Application2 from '../pages/application2';
import Kv from '../pages/kv';
import Application3 from '../pages/application3';
import Video from '../pages/video';
import Sign from '../pages/sign';


const Routes = () => {
    const element = useRoutes([
        {
            path: '/login',
            element: <Login />,
        },
        // {
        //     path: 'sign',
        //     element: <Sign />,
        // },
        {
            path: 'application',
            element: <Login />,
        },
        // {
        //     path: 'application3',
        //     element: <Application3/>,
        // },
        // {
        //     path: 'kv',
        //     element: <Kv/>,
        // },
        // {
        //     path: 'video',
        //     element: <Video/>,
        // },
        {
            path: 'home',
            element: <Home />,
            children:[
                {
                    path: '/home/application',
                    element: <Application />,
                },
                {
                    path: '/home/information',
                    element: <Information />,
                },
                {
                    path: '/home/recommend',
                    element: <Recommend />,
                },
                {
                    path: '/home/moment',
                    element: <Moment />,
                },
                
                {
                    path: '/home',
                    element: <Navigate to='/home/application' replace/>,
                },
            ]
        },
        {
            path: '/',
            element: <Navigate to='/login' replace/>,
        },

    ])
    return element
}

export default Routes;
