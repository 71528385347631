import React from 'react';
import { useState } from 'react';
import './index.css'
import { useUpdateMutation, useInfoMutation, useTimesMutation } from '../../store/pageApi2';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { changeXuan } from '../../store/pageSlice';
import wx from 'weixin-js-sdk'
import { useShareMutation } from '../../store/shareApi';
import { useEffect } from 'react';

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Translate from '../../components/Translate';
import { useRegisterMutation } from '../../store/pageApi';



const Application = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [success, setSuccess] = useState(false)
    const [lang, setLang] = useState('zh')
    const [tijiao, settijiao] = useState(true)
    const [userId, setUserId] = useState('')
    const [times, setTimes] = useState([])
    const [erweima, setErweima] = useState('')
    const [site, setSite] = useState([])
    const [company, setCompany] = useState(false)


    const [update, { isSuccess }] = useUpdateMutation()
    const [getInfo] = useInfoMutation()
    const [getTimes] = useTimesMutation()
    const nav = useNavigate()

    const { pageSlice } = useSelector(state => state);

    const [count] = useSearchParams()
    const [register] = useRegisterMutation()

    const phoneTest = function (value) {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
        if (
            !reg_tel.test(value)
        ) {
            return false
        } else {
            return true
        }
    }
    const nameTest = (str) => {
        //验证是否是中文

        var pattern = new RegExp("[\u4E00-\u9FA5]+");
        if (pattern.test(str)) {
            if (str.length > 4) {
                return false
            }
        }
        return true
    }

    const SignupSchema2 = Yup.object().shape({
        name: Yup.string()
            .required(t("alert2"))
            .test('name', '姓名最多4个字', nameTest),
        compony: Yup.string()
            .required(t("alert1")),
        phone: Yup.string()
            .test('phone', t("alert3-1"), phoneTest)
            .required(t("alert3-2")),
        try: Yup.string()
            .required(t("请选择是否试驾")),


        // idcard: Yup.string().when("id_type", {
        //     is: (value) => value == '1',
        //     then: () => Yup.string()
        //         .required(t('请输入身份证号码'))
        //         .test('idcard', t('请输入正确的身份证号码'), idcardTest),
        // }),
        // huzhao: Yup.string().when("id_type", {
        //     is: (value) => value == '2',
        //     then: () => Yup.string()
        //         .required(t('请输入护照号码'))
        //     // .test('idcard', t('请输入正确的护照号码'), idcardTest),
        // }),


    })


    useEffect(() => {

        dispatch(changeXuan(3))
        getTimes({

        }).then(res => {

            setTimes(res.data)
        });
        getInfo({
            token: localStorage.getItem('peng_token')
        }).then(res => {

            console.log(res.data)
            if (res.data.rs === 1) {
                setUserId(res.data.user_id)
                if (res.data.status >= 1) {
                    if (res.data.company) {
                        setCompany(true)
                        // setSuccess(true)
                        setErweima(res.data.qr_code)
                        if (res.data.site) {
                            setSite(res.data.site)
                        }
                    } else {
                        setCompany(false)
                    }


                }
            } else if (res.data.rs === 0) {
                localStorage.removeItem('peng_token')
                localStorage.removeItem('peng_time')
                nav('/', { replace: true })
            } else {
                alert(res.data.msg);
            }
        });

        // getTimes({

        // }).then(res => {
        //     console.log(res.data)
        //     setTimes(res.data)
        // });


    }, [])

    const successItem = () => {
        return (
            <div className='successItem'>
                <img src="/img/2.jpeg" alt="" className='bg' />
                <div className='success'>
                    {
                        t("is_en") == 1 ?
                            <img src="/img/success-en-22.png" alt="" style={{ width: '590px' }} /> :
                            <img src="/img/success-img.png" alt="" />
                    }

                </div>

                {/* <Translate /> */}
                <div className='info'>
                    <img src={erweima} alt="" className='erweima' />
                    <div className='text1'>
                        {t("我的参会码")}
                    </div>
                    <div className='text2'>

                        <h2>{t("参会id")}{userId}</h2>
                    </div>
                    <div className='text3'>



                        {/* <h2>{t("晚宴坐席: ")}{t("待更新")}
                        {
                            site.length>0?t("is_en") == 1 ? site.table_en : site.table:null
                        }
                        </h2>  */}

                    </div>
                </div>
                <div className={t("is_en") == 1 ? 'shuoming-en' : 'shuoming'} >
                    <h2>{t("使用说明")}</h2>
                    <p>
                        {t("说明1")}
                        <br />
                        {t("说明2")}
                        <br />
                        {t("说明3")}
                        <br />
                        {t("说明4")}
                    </p>
                </div>
            </div>
        )
    }

    const applicationItem = () => {
        return (
            <div style={{
                // backgroundImage: "linear-gradient(rgb(224, 235, 241),#e6ebf1, rgb(223, 233, 242))",
                fontWeight: '400', maxWidth: '750px', margin: '0 auto', position: 'relative', fontSize: '24px'
            }} className='application2'>

                {/* <div className='liuguang'>

                </div> */}
                {
                    lang === 'zh' ? <img src="/img/2.jpeg" alt="" style={{ width: '100%' }} /> :
                        <img src="/img/hui-top-29.jpg" alt="" style={{ width: '100%' }} />
                }

                {/* {
                    dong ? <Kv className='kv' /> : null
                } */}



                <div className="container" style={{ padding: '0 80px', margin: '0 auto', paddingTop: '20px', paddingBottom: '120px', marginTop: '30px' }}>



                    <Formik
                        initialValues={{
                            compony: '',
                            name: '',
                            phone: '',
                            try: '',

                        }}

                        validationSchema={SignupSchema2}
                        onSubmit={values => {

                            settijiao(false)

                            register({
                                company: values.compony,
                                name: values.name,
                                phone: values.phone,
                                sj: values.try,

                                channel: count.get('channel')


                            }).then(res => {
                                console.log(res.data)
                                setSuccess(true)
                                settijiao(true)

                                if (res.data.rs == 1) {
                                    getInfo({
                                        token: localStorage.getItem('peng_token')
                                    }).then(res => {

                                        console.log(res.data)
                                        if (res.data.rs === 1) {
                                            setUserId(res.data.user_id)
                                            setSuccess(false)
                                            console.log(res.data)
                                            if (res.data.status >= 1) {
                                                
                                                if (res.data.company) {
                                                    setCompany(true)
                                                    
                                                    setErweima(res.data.qr_code)
                                                    if (res.data.site) {
                                                        setSite(res.data.site)
                                                    }
                                                } else {
                                                    setCompany(false)
                                                }


                                            }
                                        } else if (res.data.rs === 0) {
                                            localStorage.removeItem('peng_token')
                                            localStorage.removeItem('peng_time')
                                            nav('/', { replace: true })
                                        } else {
                                            alert(res.data.msg);
                                        }
                                    });


                                } else {
                                    alert(res.data.msg);
                                }
                            });



                        }}
                    >
                        {({ values, handleChange, handleBlur, errors, touched }) => (
                            <Form>
                                <div className="form-group">
                                    <div className='dash-title1'>{t("Company")}</div>

                                    <input type="input" name="compony"
                                        value={values.compony}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.compony && touched.compony ?
                                            "input-error form-control input-text" : "form-control input-text"}
                                    />
                                    {errors.compony && touched.compony ? (
                                        <div className='err'>{errors.compony}</div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <div className='dash-title1'>{t("Participant")}</div>
                                    <input type="input" name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.name && touched.name ?
                                            "input-error form-control input-text" : "form-control input-text"}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className='err'>{errors.name}</div>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <div className='dash-title1'>{t("Mobile")}</div>
                                    <p className='xiaozi2'>{t("*此号码用于接收参会信息以及嘉宾现场签到")}</p>

                                    <input type="input" name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.phone && touched.phone ?
                                            "input-error form-control input-text" : "form-control input-text"}
                                    />
                                    {errors.phone && touched.phone ? (
                                        <div className='err'>{errors.phone}</div>
                                    ) : null}
                                </div>



                                <div className="form-group">
                                    <div className='title1'>{t("是否参加试驾/试乘")}</div>
                                    <div className="jiantou_kuang" >
                                        <img src="/img/jiantou.png" alt="" className='jiantou2' />
                                        <select name="try"
                                            value={values.try}
                                            onChange={(e) => {
                                                handleChange(e);

                                                if (e.target.value > 0) {

                                                } else {
                                                    values.id_type = '';
                                                    values.huzhao = ''
                                                    values.idcard = ''
                                                }

                                            }}
                                            onBlur={handleBlur}
                                            className={errors.try && touched.try ?
                                                "input-error form-control form-control-home" : "form-control form-control-home"}>
                                            <option value=''></option>



                                            {
                                                t("is_en") == 1 ? <>
                                                    {
                                                        times ? times.map((e, index) => {
                                                            return <option value={e.id} key={e.id}> {e.range_en}</option>
                                                        }) : null
                                                    }
                                                </> : <>
                                                    {
                                                        times ? times.map((e, index) => {
                                                            return <option value={e.id} key={e.id}> {e.range}</option>
                                                        }) : null
                                                    }
                                                </>
                                            }



                                            {
                                                times && times.length === 0 ? <option value=''>{t('p2-该预约时段已满')}</option> : null
                                            }
                                            <option value='0'>{t("不参加试驾/试乘")}</option>

                                        </select>
                                    </div>
                                    {errors.try && touched.try ? (
                                        <div className='err'>{errors.try}</div>
                                    ) : null}
                                    <div className='title1' style={{ marginTop: '20px' }}>感谢您参与小鹏汽车试乘试驾体验</div>
                                </div>




                                {/* {
                                    values.try > 0 ? <>
                                        <div className="form-group">
                                            <div className='title1'>{t("感谢您参与小鹏汽车试乘试驾体验")}</div>
                                            <div className='liuyi'>
                                                {t("请留意")}

                                                {
                                                    t("is_en") == 0 ? <>
                                                        <br />
                                                        {t("同时我们")}
                                                    </> : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            t("is_en") == 1 ?
                                                <div className="form-group" style={{ display: 'flex' }}>
                                                    <div >{t("证件类型")}</div>
                                                    <div >
                                                        <label className="radio-inline baseline-input" style={{ marginLeft: '70px' }}>
                                                            <input
                                                                type="radio"
                                                                name="id_type"
                                                                value='1'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {t("身份证")}
                                                            </div>
                                                        </label>
                                                        <label className="radio-inline baseline-input" style={{ marginLeft: '60px' }}>
                                                            <input
                                                                type="radio"
                                                                name="id_type"
                                                                value='2'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {t("护照")}
                                                            </div>
                                                        </label>
                                                    </div>

                                                </div> :
                                                <div className="form-group" style={{ display: 'flex' }}>
                                                    <div >{t("证件类型")}</div>
                                                    <div >
                                                        <label className="radio-inline baseline-input" style={{ marginLeft: '95px' }}>
                                                            <input
                                                                type="radio"
                                                                name="id_type"
                                                                value='1'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {t("身份证")}
                                                            </div>
                                                        </label>
                                                        <label className="radio-inline baseline-input" style={{ marginLeft: '80px' }}>
                                                            <input
                                                                type="radio"
                                                                name="id_type"
                                                                value='2'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <div style={{ marginLeft: '10px' }}>
                                                                {t("护照")}
                                                            </div>
                                                        </label>
                                                    </div>

                                                </div>
                                        }

                                        {errors.id_type && touched.id_type ? (
                                            <div className='err'>{errors.id_type}</div>
                                        ) : null}

                                    </> : null
                                }
                                {
                                    values.id_type == 1 && values.try > 0 ?
                                        <div className="form-group">
                                            <div className='dash-title1'>{t("身份证号码")}</div>
                                            <input type="input" name="idcard"
                                                value={values.idcard}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.idcard && touched.idcard ?
                                                    "input-error form-control input-text" : "form-control input-text"}
                                            />
                                            {errors.idcard && touched.idcard ? (
                                                <div className='err'>{errors.idcard}</div>
                                            ) : null}
                                        </div> : null

                                }

                                {
                                    values.id_type == 2 && values.try > 0 ?
                                        <div className="form-group">
                                            <div className='dash-title1'>{t("护照号码")}</div>
                                            <input type="input" name="huzhao"
                                                value={values.huzhao}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.huzhao && touched.huzhao ?
                                                    "input-error form-control input-text" : "form-control input-text"}
                                            />
                                            {errors.huzhao && touched.huzhao ? (
                                                <div className='err'>{errors.huzhao}</div>
                                            ) : null}
                                        </div> : null

                                } */}






                                {/* {
                                    t("is_en") == 1 ?
                                        <div className="form-group" >
                                            <div >{t("交通方式")}</div>
                                            <div className='en'>
                                                <label className="radio-inline baseline-input" >
                                                    <input
                                                        type="radio"
                                                        name="ty_type"
                                                        value='1'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {t("自驾")}
                                                    </div>
                                                </label>
                                                <label className="radio-inline baseline-input" style={{ marginLeft: '120px' }}>
                                                    <input
                                                        type="radio"
                                                        name="ty_type"
                                                        value='2'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {t("非自驾")}
                                                    </div>
                                                </label>
                                            </div>

                                        </div> :
                                        <div className="form-group" style={{ display: 'flex' }}>
                                            <div >{t("交通方式")}</div>
                                            <div >
                                                <label className="radio-inline baseline-input" style={{ marginLeft: '95px' }}>
                                                    <input
                                                        type="radio"
                                                        name="ty_type"
                                                        value='1'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {t("自驾")}
                                                    </div>
                                                </label>
                                                <label className="radio-inline baseline-input" style={{ marginLeft: '85px' }}>
                                                    <input
                                                        type="radio"
                                                        name="ty_type"
                                                        value='2'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <div style={{ marginLeft: '10px' }}>
                                                        {t("非自驾")}
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                }



                                {errors.ty_type && touched.ty_type ? (
                                    <div className='err'>{errors.ty_type}</div>
                                ) : null} */}




                                {
                                    success && tijiao ? (
                                        <div className='suceess_page'>
                                            <div style={{ height: '220px' }}></div>
                                            <div style={{
                                                width: '2000px',
                                                height: '2000px',
                                                position: 'fixed',
                                                top: '0px',
                                                backgroundColor: 'white',
                                                opacity: 0.4,
                                                left: 0
                                            }}></div>
                                            <div style={{}}>
                                                <img src="/img/tan.png" style={{
                                                    width: '750px',
                                                    padding: '0 100px',
                                                    position: 'fixed',
                                                    bottom: '50%',
                                                    left: '50%',
                                                    marginLeft: '-375px',
                                                    marginBottom: '-130px',
                                                }} alt='' />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="form-group">

                                            {tijiao ? <button className="btn btn-default form-control btn-submit baoming"
                                                style={{ margin: '80px 0 80px 0', color: '#fff' }}
                                                type="submit">报名提交 SUBMIT
                                            </button> : <button className="btn btn-default form-control btn-submit baoming"
                                                style={{ margin: '80px 0 80px 0', color: '#fff' }}
                                                type="submit" disabled>报名提交 SUBMIT
                                            </button>}


                                        </div>
                                    )

                                }

                            </Form>
                        )}
                    </Formik>

                </div>

            </div>
        )
    }

    return (
        company ? successItem() : applicationItem()
    );
}

export default Application;
